export default {
    getApiUrl: state => state.global.api,
    getApiVersion: state => state.global.version,
    getFinderV2Url: state => state.global.finderV2Url,
    getToken: () => localStorage.getItem("token"),
    getLoading: state => state.global.loading,
    getLoadingMessage: state => state.global.loadingMessage,
    getBreakpoint: state => state.global.breakpoint,
    getUser: state => state.global.user,
    getUserPermissions: state => {
        let permissionsArray = state.global.userPermissions.map(r => r.name);
        return [].concat.apply([], permissionsArray);
    },
    getPusherNotification: state => state.global.pusherUpdate
};
