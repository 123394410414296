<template>
  <div class="c-menu">
    <ul class="nav flex-column c-menu-inner">
      <MenuOption :icon-src="home" link-name="Home"/>
      <MenuOption v-if="checkENVDev" :icon-src="edit" link-name="Application Form"/>
      <MenuOption :icon-src="chalkboard" link-name="Applications"/>
      <MenuOption :icon-src="fileUpload" link-name="File Upload"/>
      <MenuOption :icon-src="rule" link-name="App/Trace Rules"/>
      <MenuOption
          :icon-src="email"
          :link-name="['Email Manager', 'Email Thread Viewer']">
        <template #alert>
          <EmailAlert/>
        </template>
      </MenuOption>
      <MenuOption :icon-src="table" link-name="Pension Providers"/>
      <MenuOption :external-link="translateURL" :icon-src="robot" link-name="Translate"/>
      <MenuOption :external-link="finderURL" :icon-src="finder" link-name="Finder"/>
      <MenuOption :external-link="flowv2URL" :icon-src="cubes" link-name="Flow V2"/>
      <MenuOption :icon-src="signOut" link-name="Logout"/>
    </ul>
    <span class="c-menu-control" @click="handleCollapse">
      <icon
          :data="arrowRight"
          :dir="getCollapsed ? 'up' : 'down'"
          color="white"
          height="20"
          width="20"
      />
    </span>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import viewport from "@/helpers/viewport";
import arrowRight from "@fa/solid/arrow-right.svg";
import signOut from "@fa/solid/sign-out-alt.svg";
import edit from "@fa/regular/edit.svg";
import chalkboard from "@fa/solid/chalkboard-teacher.svg";
import wpforms from "@fa/solid/robot.svg";
import email from "@fa/solid/mail-bulk.svg";
import home from "@fa/solid/home.svg";
import fileUpload from "@fa/solid/file-upload.svg";
import aggregationReport from "@fa/solid/clipboard-list.svg";
import excel from "@fa/solid/clipboard-check.svg";
import table from "@fa/solid/table.svg";
import rule from "@fa/solid/pencil-ruler.svg"
// import shareFromSquare from "@fa/regular/share-from-square.svg"
import robot from "@fa/solid/robot.svg"
import finder from "@fa/solid/fingerprint.svg";
import cubes from "@fa/solid/cubes.svg";
import MenuOption from "@/components/navigation/MenuOption";
import EmailAlert from "@/components/email-manager/EmailAlert";

export default {
  name: "VMenu",
  data: () => ({
    arrowRight,
    signOut,
    edit,
    chalkboard,
    wpforms,
    home,
    aggregationReport,
    excel,
    fileUpload,
    email,
    rule,
    table,
    // shareFromSquare,
    robot,
    finder,
    cubes,
    iconWidth: 20,
    iconHeight: 20,
  }),
  components: {
    MenuOption,
    EmailAlert,
  },
  watch: {
    getBreakpoint(n) {
      if (n === "xs") this.setCollapse(true);
    }
  },
  computed: {
    ...mapGetters(["getCollapsed", "getBreakpoint"]),
    translateURL() {
      return process.env.VUE_APP_TRANSLATE_V2_URL
    },
    finderURL() {
      return process.env.VUE_APP_FINDER_URL + '/Traces'
    },
    flowv2URL() {
      return process.env.VUE_APP_FLOWV2_URL + '/#/Home'
    },
  },
  methods: {
    ...mapMutations(["setCollapse"]),
    handleCollapse() {
      this.setCollapse(!this.getCollapsed);
    },
    isActive(route) {
      return route === this.$route.name;
    },
    isLogout(route) {
      return route === "Logout";
    },
    gotoTranslateDashboard() {
      window.open(process.env.VUE_APP_TRANSLATE_V2_URL, '_blank');
    },
    gotoFinderDashboard() {
      window.open(process.env.VUE_APP_FINDER_URL, '_blank');

    }
  },
  mounted() {
    if (viewport.is("xs")) {
      this.setCollapse(true);
    }
  }
};
</script>
