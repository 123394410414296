import {createStore} from "vuex";
import globalState from "@/store/state/globalState";
import menuState from "@/store/state/menuState";
import applicationState from "@/store/state/applicationState";
import applicationManagementState from "@/store/state/applicationManagementState";
import mlState from "@/store/state/mlState";
import notificationState from "@/store/state/notificationState";
import associationState from "@/store/state/associationState";
import emailManagerState from "@/store/state/emailManagerState";

import authActions from "@/store/actions/authActions";
import applicationActions from "@/store/actions/applicationActions";
import mlActions from "@/store/actions/mlActions";
import miActions from "@/store/actions/miActions";
import aggregationActions from "@/store/actions/aggregationActions";
import userActions from "@/store/actions/userActions";
import providerActions from "@/store/actions/providerActions";
import applicationManagementActions from "@/store/actions/applicationManagementActions";
import finderActions from "@/store/actions/finderActions";
import employmentActions from "@/store/actions/employmentActions";
import emailActions from "@/store/actions/emailActions";
import spamFilter from "@/store/actions/spamFilter";
import traceRuleActions from "@/store/actions/traceRuleActions";
import emailManagerActions from "@/store/actions/emailManagerActions";
import traceActions from "@/store/actions/traceActions";

import globalGetters from "@/store/getters/globalGetters";
import menuGetters from "@/store/getters/menuGetters";
import applicationGetters from "@/store/getters/applicationGetters";
import applicationManagementGetters from "@/store/getters/applicationManagementGetters";
import mlGetters from "@/store/getters/mlGetters";
import notificationGetters from "@/store/getters/notificationGetters";
import associationGetters from "@/store/getters/associationGetters";
import emailManagerGetters from "@/store/getters/emailManagerGetters";

import globalMutations from "@/store/mutations/globalMutations";
import menuMutations from "@/store/mutations/menuMutations";
import applicationMutations from "@/store/mutations/applicationMutations";
import applicationManagementMutations from "@/store/mutations/applicationManagementMutations";
import mlMutations from "@/store/mutations/mlMutations";
import notificationMutations from "@/store/mutations/notificationMutations";
import associationMutations from "@/store/mutations/associationMutations";
import emailManagerMutations from "@/store/mutations/emailManagerMutations";

export default createStore({
    state: {
        ...globalState,
        ...menuState,
        ...applicationState,
        ...applicationManagementState,
        ...mlState,
        ...associationState,
        ...emailManagerState,
        ...notificationState
    },
    actions: {
        ...authActions,
        ...applicationActions,
        ...applicationManagementActions,
        ...mlActions,
        ...miActions,
        ...aggregationActions,
        ...userActions,
        ...emailManagerActions,
        ...providerActions,
        ...providerActions,
        ...emailActions,
        ...spamFilter,
        ...traceRuleActions,
        ...employmentActions,
        ...traceActions,
        ...finderActions
    },
    getters: {
        ...globalGetters,
        ...menuGetters,
        ...applicationGetters,
        ...applicationManagementGetters,
        ...mlGetters,
        ...associationGetters,
        ...emailManagerGetters,
        ...notificationGetters
    },
    mutations: {
        ...globalMutations,
        ...menuMutations,
        ...applicationMutations,
        ...applicationManagementMutations,
        ...mlMutations,
        ...associationMutations,
        ...emailManagerMutations,
        ...notificationMutations
    }
});
